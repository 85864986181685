import React, { ReactNode } from "react";
import { AppNavbar } from "./appNavbar";
import { AppSpinner } from "./appSpinner";
import Container from "react-bootstrap/Container";

type Props = {
    children: ReactNode;
    loading?: boolean;
}

export const AppLayout = ({ children, loading }: Props) => {
    return (
        <>
            <AppNavbar/>
            {loading ?
                <Container fluid>
                    <AppSpinner/>
                </Container>
                : children
            }
        </>
    );
}