import { useFormik } from "formik";
import {
    BookmarkViewModel,
    CategoryWithCountsViewModel, CreateBookmarkBindingModel,
    UpdateBookmarkBindingModel,
    VideoLibraryApiClient
} from "../../apiClient";
import React from "react";
import Form from "react-bootstrap/Form";
import { CategorySelect } from "../inputs/categorySelect";
import Stack from "react-bootstrap/esm/Stack";
import Button from "react-bootstrap/Button";

type FormValues = {
    name?: string;
    categoryName: string;
    url: string;
}

type Props = {
    apiClient: VideoLibraryApiClient;
    bookmark?: BookmarkViewModel;
    categories: CategoryWithCountsViewModel[];
    onSave: () => void;
}

export const BookmarkAddEditForm = ({ apiClient, bookmark, categories, onSave }: Props) => {
    const isEdit = bookmark?.id !== undefined;
    const formik = useFormik<FormValues>({
        initialValues: {
            name: bookmark?.name ?? '',
            categoryName: bookmark?.categoryName ?? '',
            url: bookmark?.url ?? '',
        },
        onSubmit: async (values, helpers) => {
            try {
                if (isEdit) {
                    await apiClient?.bookmarksPUT(bookmark.id, new UpdateBookmarkBindingModel({
                        name: values.name!,
                        categoryName: values.categoryName !== '' ? values.categoryName : null,
                    }));
                } else {
                    await apiClient?.bookmarksPOST(new CreateBookmarkBindingModel({
                        url: values.url,
                        categoryName: values.categoryName !== '' ? values.categoryName : null,
                    }));
                }
                onSave();
            } catch (e) {
                console.error(e);
            } finally {
                helpers.setSubmitting(false);
            }
        }
    });

    return (
        <Form onSubmit={formik.handleSubmit}>
            <fieldset disabled={formik.isSubmitting}>
                <Stack direction="vertical" gap={3}>
                    {!isEdit &&
                        <Form.Group>
                            <Form.Label>URL</Form.Label>
                            <Form.Control type="text"
                                          placeholder="https://www.youtube.com/watch?v=..." {...formik.getFieldProps('url')} />
                        </Form.Group>}
                    {isEdit &&
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" {...formik.getFieldProps('name')} />
                        </Form.Group>}
                    <Form.Group>
                        <CategorySelect categories={categories} {...formik.getFieldProps('categoryName')} />
                    </Form.Group>
                    <Stack direction="horizontal" gap={2}>
                        <Button variant="primary" type="submit" disabled={formik.isSubmitting}>Save</Button>
                        <Button variant="secondary" onClick={() => onSave()}>Cancel</Button>
                    </Stack>
                </Stack>
            </fieldset>
        </Form>
    );
}