import { Pagination } from "react-bootstrap";

type Props = {
    page: number;
    pageSize: number;
    totalPages: number;
    totalCount: number;
    onPageChange: (page: number) => void;
}

export const AppPagination = ({ page, pageSize, totalPages, totalCount, onPageChange }: Props) => {
    const middlePageNumbers = Array.from({ length: 10 }, (_, i) => page - 5 + i).filter(pageNumber => pageNumber > 0 && pageNumber <= totalPages);

    return (
        <Pagination>
            <Pagination.First onClick={() => onPageChange(1)} disabled={page === 1}/>
            <Pagination.Prev onClick={() => onPageChange(page - 1)} disabled={page === 1}/>
            {middlePageNumbers.map((pageNumber) => (
                <Pagination.Item key={pageNumber} active={pageNumber === page}
                                 onClick={() => onPageChange(pageNumber)}>{pageNumber}</Pagination.Item>
            ))}
            <Pagination.Next onClick={() => onPageChange(page + 1)} disabled={page === totalPages}/>
            <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={page === totalPages}/>
        </Pagination>
    )
};