import React from "react";
import Form from "react-bootstrap/Form";

import { uncategorizedText } from "../../constants";
import { CategoryWithCountsViewModel } from "../../apiClient";

type Props = {
    name: string;
    categories: CategoryWithCountsViewModel[];
    onChange: (e: React.ChangeEvent<any>) => void;
    value: string | undefined;
};

export const CategorySelect = (props: Props) => {
    return (
        <>
            <Form.Label>Category</Form.Label>
            <Form.Select name={props.name} onChange={props.onChange}
                         value={props.value}>
                <option value={undefined}>{uncategorizedText}</option>
                {props.categories.map((category) => (
                    <option key={category.name}>{category.name}</option>
                ))}
            </Form.Select>
        </>
    );
}