import React, { useEffect, useState } from "react";
import { ButtonGroup, Form, Modal } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Creatable from 'react-select/creatable';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag, faTimes } from "@fortawesome/free-solid-svg-icons";
import Stack from "react-bootstrap/Stack";

import {
    TagAction,
    TagWithVideoCountViewModel,
    ToggleVideoTagBindingModel,
    VideoLibraryApiClient,
    VideoViewModel
} from "../apiClient";
import { uncategorizedText } from "../constants";
import { confirmAction } from "../helpers/alertHelper";

type Props = {
    video: VideoViewModel;
    tags: TagWithVideoCountViewModel[];
    apiClient: VideoLibraryApiClient;
    onVideoDeleted?: (video: VideoViewModel) => void;
    onVideoToggleTag?: (bindingModel: ToggleVideoTagBindingModel) => void;
    onTagClick?: (tag: string) => void;
}

export const VideoResult = (props: Props) => {
    const { onVideoToggleTag: onToggleTag, video } = props;
    const [loading, setLoading] = useState<boolean>(true);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [thumbnailBase64, setThumbnailBase64] = useState<string | null>(null);
    const [tagNameInput, setTagNameInput] = useState<string | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);  // state variable for modal

    useEffect(() => {
        const loadThumbnail = async () => {
            try {
                const thumbnail = await props.apiClient.thumbnail(video.id!);
                const reader = new FileReader();
                reader.readAsDataURL(thumbnail.data);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    setThumbnailBase64(base64data as string);
                    setLoading(false);
                }
            } catch (e) {
                console.error(e);
                setLoading(false);
            }
        }

        loadThumbnail();
    }, [video, props.apiClient]);

    const handleDeleteVideo = async () => {
        if (!await confirmAction("Are you sure you wish to delete this video?")) return;
        setDeleting(true);
        try {
            await props.apiClient.videosDELETE(video.id!);
            props.onVideoDeleted?.(video);
        } catch (e) {
            console.error(e);
        } finally {
            setDeleting(false);
        }
    }

    const handleDeleteTag = (tag: string) => {
        if (onToggleTag) {
            onToggleTag(new ToggleVideoTagBindingModel({ name: tag, action: TagAction._0 }));
        }
    }

    const handleTagSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (tagNameInput && onToggleTag) {
            onToggleTag(new ToggleVideoTagBindingModel({ name: tagNameInput, action: TagAction._1 }));
        }

        setTagNameInput(null);
        setShowModal(false);
    };

    const handleTagClick = (tag: string) => {
        props.onTagClick?.(tag);
    }

    return (
        <Card className="video-result">
            <a href={video.url!} target="_blank" rel="noreferrer noopener">
                {!loading && (
                    <Card.Img className="video-result__image" variant="top"
                              src={thumbnailBase64 ?? 'https://via.placeholder.com/720x480'}
                              alt={video.title!}/>
                )}
            </a>

            <Card.Body>
                {onToggleTag && (
                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title><FontAwesomeIcon icon={faTag}/> Add tag</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleTagSubmit}>
                                <Stack gap={3}>
                                    <Creatable
                                        value={{ value: tagNameInput, label: tagNameInput }}
                                        options={props.tags.map(tag => ({ value: tag.name!, label: tag.name! }))}
                                        onChange={selectedOption => setTagNameInput(selectedOption?.value ?? null)}
                                        onCreateOption={value => setTagNameInput(value)}
                                        placeholder="Tag name"
                                        isClearable
                                        isSearchable
                                    />
                                    <Button type="submit" disabled={!tagNameInput}>Add Tag</Button>
                                </Stack>
                            </Form>
                        </Modal.Body>
                    </Modal>)
                }

                <Card.Title>
                    <a href={video.url!} target="_blank" rel="noreferrer noopener">{video.title}</a>
                </Card.Title>

                <Card.Text>
                    <Stack gap={2}>
                        <div>
                            <Badge className="me-1">{video.categoryName ?? uncategorizedText}</Badge>
                            <Badge>{video.provider}</Badge>
                        </div>

                        {video.tags?.length !== 0 && (
                            <Stack direction="horizontal" gap={2} className="flex-wrap">
                                {video.tags?.map((tag, index) => (
                                    <Badge key={index} bg="secondary" className="p-2">
                                        <Stack direction="horizontal" gap={2}>
                                            <FontAwesomeIcon icon={faTag}/>
                                            <span role="button" onClick={() => handleTagClick(tag)}>{tag}</span>
                                            <span role="button"
                                                        onClick={() => handleDeleteTag(tag)}><FontAwesomeIcon
                                            icon={faTimes} size="lg"/></span>
                                        </Stack>
                                    </Badge>
                                ))}
                            </Stack>
                        )}

                        <ButtonGroup>
                            <Stack direction="horizontal" gap={2}>
                                <LinkContainer to={`/videos/${video.id}/edit`}>
                                    <Button variant="warning" disabled={deleting}>Edit</Button>
                                </LinkContainer>
                                {handleDeleteVideo &&
                                    <Button variant="danger" onClick={handleDeleteVideo} disabled={deleting}>Delete</Button>}
                                <Button variant="secondary" onClick={() => setShowModal(true)}><FontAwesomeIcon
                                    icon={faTag}/></Button>
                            </Stack>
                        </ButtonGroup>
                    </Stack>
                </Card.Text>
            </Card.Body>
        </Card>
    );
};