import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/esm/Stack";

import { AppLayout } from "../components/appLayout";
import useApiClient from "../hooks/useApiClient";
import { CategorySelect } from "../components/inputs/categorySelect";
import { CategoryWithCountsViewModel, CreateVideoBindingModel, CreateVideosBindingModel } from "../apiClient";

export const VideoBulkAdd = () => {
    const apiClient = useApiClient();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState<CategoryWithCountsViewModel[]>([]);

    useEffect(() => {
        if (!apiClient) return;

        const loadData = async () => {
            const categories = await apiClient.categoriesAll();
            setCategories(categories);
            setLoading(false);
        };

        loadData();
    }, [apiClient]);

    const formik = useFormik({
        initialValues: {
            categoryName: '',
            videoUrls: '',
        },
        onSubmit: async (values, helpers) => {
            if (!apiClient) return;

            try {
                const videoUrls = values.videoUrls.split('\n').filter(x => x);
                const categoryName = values.categoryName !== '' ? values.categoryName : null;
                await apiClient.videosAll(new CreateVideosBindingModel({
                    videos: videoUrls.map(url => new CreateVideoBindingModel({
                        categoryName: categoryName,
                        url: url
                    }))
                }));

                navigate('/');
            } catch (e) {
                console.error(e);
            } finally {
                helpers.setSubmitting(false);
            }
        }
    });

    return (
        <AppLayout loading={loading}>
            <Container fluid>
                <h1>Bulk add videos</h1>

                <Form onSubmit={formik.handleSubmit}>
                    <Stack gap={3}>
                        <Form.Group>
                            <Form.Label>Video URLs (one per line)</Form.Label>
                            <Form.Control as="textarea" rows={10} name="videoUrls" value={formik.values.videoUrls} onChange={formik.handleChange} required={true} />
                        </Form.Group>

                        <Form.Group>
                            <CategorySelect categories={categories} name="categoryName" value={formik.values.categoryName} onChange={formik.handleChange}  />
                        </Form.Group>

                        <span>
                            <Button variant="primary" type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                                Add videos
                            </Button>
                        </span>
                    </Stack>
                </Form>
            </Container>
        </AppLayout>
    );
}