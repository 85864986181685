import Container from "react-bootstrap/Container";

import { AppLayout } from "../components/appLayout";

export const Error = () => {
    return (
        <AppLayout>
            <Container fluid>
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
            </Container>
        </AppLayout>
    );
};