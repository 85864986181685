import React, { useCallback, useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import { AppLayout } from "../components/appLayout";
import useApiClient from "../hooks/useApiClient";
import { confirmAction } from "../helpers/alertHelper";
import { CategoryWithCountsViewModel } from "../apiClient";

export const Categories = () => {
    const [categories, setCategories] = useState<CategoryWithCountsViewModel[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [deleting, setDeleting] = useState<boolean>(false);
    const apiClient = useApiClient();

    const loadData = useCallback(async () => {
        if (!apiClient) return;
        setLoading(true);
        setCategories(await apiClient.categoriesAll());
        setLoading(false);
    }, [apiClient]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const deleteCategory = async (category: CategoryWithCountsViewModel) => {
        if (!await confirmAction()) return;
        setDeleting(true);
        await apiClient?.categoriesDELETE(category.name!);
        setDeleting(false);
        await loadData();
    }

    return (
        <AppLayout loading={loading}>
            <Container fluid>
                <Row>
                    <Col>
                        <h1>Categories ({categories.length})</h1>
                    </Col>
                    <Col xs="auto">
                        <LinkContainer to={"/categories/add"}>
                            <Button>Add category</Button>
                        </LinkContainer>
                    </Col>
                </Row>

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Bookmark count</th>
                        <th>Video count</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {categories.map((category) => (
                        <tr key={category.name}>
                            <td>{category.name}</td>
                            <td>{category.bookmarkCount}</td>
                            <td>{category.videoCount}</td>
                            <td>
                                <ButtonGroup>
                                    <Button variant="danger" onClick={() => deleteCategory(category)} disabled={deleting}>Delete</Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Container>
        </AppLayout>
    )
}