import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { Link } from "react-router-dom";

import { AppLayout } from "../components/appLayout";
import useApiClient from "../hooks/useApiClient";
import { TagWithVideoCountViewModel } from "../apiClient";

export const Tags = () => {
    const [tags, setTags] = useState<TagWithVideoCountViewModel[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const apiClient = useApiClient();

    const loadData = useCallback(async () => {
        if (!apiClient) return;
        setLoading(true);
        setTags(await apiClient.tagsAll());
        setLoading(false);
    }, [apiClient]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <AppLayout loading={loading}>
            <Container fluid>
                <Row>
                    <Col>
                        <h1>Tags ({tags.length})</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Stack direction="horizontal" gap={2} className="flex-wrap">
                            {tags.map((tag) => (
                                <Link to={`/?tag=${tag.name}`} key={tag.name} className="text-decoration-none">
                                    <Badge className="mr-2">{tag.name}</Badge>
                                </Link>
                            ))}
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </AppLayout>
    )
}