import './index.scss';

import ReactDOM from 'react-dom/client';
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { BookmarkAdd } from "./routes/bookmarkAdd";
import { Bookmarks } from "./routes/bookmarks";
import { Categories } from "./routes/categories";
import { CategoryAdd } from "./routes/categoriesAdd";
import { Error } from "./routes/error";
import { Home } from "./routes/home";
import { VideoAdd } from "./routes/videoAdd";
import { VideoBulkAdd } from './routes/videoBulkAdd';
import { VideoEdit } from "./routes/videoEdit";

import { msalClientId } from "./constants";
import reportWebVitals from './reportWebVitals';
import { Tags } from './routes/tags';
import { BookmarkEdit } from "./routes/bookmarkEdit";
import { Backup } from "./routes/backup";

const pca = new PublicClientApplication({
    auth: {
        clientId: msalClientId,
        authority: 'https://login.microsoftonline.com/7cc1b8c0-297e-4d5f-a6e0-e5f7518d3e4e',
    },
});

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        errorElement: <Error/>
    },
    {
        path: "/backup",
        element: <Backup/>
    },
    {
        path: "/bookmarks/add",
        element: <BookmarkAdd/>
    },
    {
        path: "/bookmarks",
        element: <Bookmarks/>
    },
    {
        path: "/bookmarks/:id/edit",
        element: <BookmarkEdit/>
    },
    {
        path: "/categories/add",
        element: <CategoryAdd/>
    },
    {
        path: "/categories",
        element: <Categories/>
    },
    {
        path: "/tags",
        element: <Tags/>
    },
    {
        path: "/videos/add/bulk",
        element: <VideoBulkAdd/>
    },
    {
        path: "/videos/add",
        element: <VideoAdd/>
    },
    {
        path: "/videos/:id/edit",
        element: <VideoEdit/>
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <RouterProvider router={router}/>
        </MsalAuthenticationTemplate>
    </MsalProvider>
);

reportWebVitals();
