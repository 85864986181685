import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";

import { AppLayout } from "../components/appLayout";
import { VideoAddEditForm } from "../components/forms/videoAddEditForm";
import useApiClient from "../hooks/useApiClient";
import { CategoryWithCountsViewModel } from "../apiClient";

export const VideoAdd = () => {
    const apiClient = useApiClient();
    const navigate = useNavigate();
    const [categories, setCategories] = useState<CategoryWithCountsViewModel[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!apiClient) return;

        const loadData = async () => {
            const categories = await apiClient.categoriesAll();
            setCategories(categories);
            setLoading(false);
        };

        loadData();
    }, [apiClient]);

    return (
        <AppLayout loading={loading}>
            <Container fluid>
                <h1>Add video</h1>
                <VideoAddEditForm apiClient={apiClient!} categories={categories} onSave={() => navigate(`/`)}/>
            </Container>
        </AppLayout>
    );
}