import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";

import { AppLayout } from "../components/appLayout";
import { VideoAddEditForm } from "../components/forms/videoAddEditForm";
import useApiClient from "../hooks/useApiClient";
import { CategoryWithCountsViewModel, VideoViewModel } from "../apiClient";

export const VideoEdit = () => {
    const routeParams = useParams();
    const apiClient = useApiClient();
    const navigate = useNavigate();
    const [categories, setCategories] = useState<CategoryWithCountsViewModel[]>([]);
    const [video, setVideo] = useState<VideoViewModel | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!apiClient) return;

        const loadData = async () => {
            const categoriesPromise = apiClient.categoriesAll().then(categories => setCategories(categories));
            const videoPromise = apiClient.videosGET(routeParams.id!).then(video => setVideo(video));
            await Promise.all([categoriesPromise, videoPromise]);
            setLoading(false);
        };

        loadData();
    }, [apiClient, setCategories, setVideo, routeParams]);

    return (
        <AppLayout loading={loading}>
            <Container fluid>
                <h1>Edit video: {video?.title}</h1>
                <VideoAddEditForm apiClient={apiClient!} video={video!} categories={categories} onSave={() => navigate(`/`)}/>
            </Container>
        </AppLayout>
    );
}