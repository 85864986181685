import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useAccount, useMsal } from "@azure/msal-react";

import { VideoLibraryApiClient } from "../apiClient";
import { accessAsUserScope, apiBaseUrl } from "../constants";

export default function useApiClient() {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const [apiClient, setApiClient] = useState<VideoLibraryApiClient | null>(null);

    const setAuthenticatedApiClient = useCallback(async () => {
        const authenticationResult = await instance.acquireTokenSilent({
            scopes: [accessAsUserScope],
            account: account!
        });

        const axiosClient = axios.create({
            baseURL: apiBaseUrl,
            headers: { Authorization: `Bearer ${authenticationResult.accessToken}` }
        });

        const client = new VideoLibraryApiClient(apiBaseUrl, axiosClient);
        setApiClient(client);
    }, [account, instance]);

    useEffect(() => {
        setAuthenticatedApiClient();
    }, [setAuthenticatedApiClient]);

    return apiClient;
}