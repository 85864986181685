import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useMsal } from "@azure/msal-react";
import { faSearch, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createSearchParams, useNavigate } from 'react-router-dom';

export const AppNavbar = () => {
    const msalInstance = useMsal();
    const navigate = useNavigate();

    const logout = async () => {
        await msalInstance.instance.logoutRedirect();
    }

    const search = () => {
        const query = prompt("Search for videos");
        if (query) {
            navigate({
                pathname: '/',
                search: createSearchParams({ query }).toString()
            });
        }
    }

    return (
        <Navbar expand="lg" className="bg-body-tertiary mb-3">
            <Container fluid>
                <LinkContainer to={`/`}>
                    <Navbar.Brand><FontAwesomeIcon icon={faVideo} title={`Video Library`} size="lg"/></Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="navbar-nav"/>
                <Navbar.Collapse id="navbar-nav">
                    <Nav className="me-auto">
                        <LinkContainer to={"/"}>
                            <Nav.Link>Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={"/videos/add"}>
                            <Nav.Link>Add video</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={"/videos/add/bulk"}>
                            <Nav.Link>Bulk add videos</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={"/bookmarks"}>
                            <Nav.Link>Bookmarks</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={"/categories"}>
                            <Nav.Link>Categories</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={"/tags"}>
                            <Nav.Link>Tags</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={"/backup"}>
                            <Nav.Link>Backup</Nav.Link>
                        </LinkContainer>
                    </Nav>
                    <Nav>
                        <Nav.Link onClick={search}><FontAwesomeIcon icon={faSearch}
                                                                    title="Search"></FontAwesomeIcon></Nav.Link>
                        <Nav.Link onClick={logout}>Logout</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}