import { useFormik } from "formik";
import { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/esm/Stack";

import {
    CategoryWithCountsViewModel,
    CreateVideoBindingModel,
    CreateVideosBindingModel,
    ResolveVideoBindingModel,
    UpdateVideoBindingModel,
    VideoLibraryApiClient,
    VideoViewModel
} from "../../apiClient";
import { CategorySelect } from "../inputs/categorySelect";

type FormValues = {
    title: string;
    categoryName: string;
    url: string;
}

type Props = {
    apiClient: VideoLibraryApiClient;
    video?: VideoViewModel;
    categories: CategoryWithCountsViewModel[];
    onSave: () => void;
}

export const VideoAddEditForm = ({ apiClient, video, categories, onSave }: Props) => {
    const [resolvingVideoTitle, setResolvingVideoTitle] = useState<boolean>(false);
    const isEdit = video?.id !== undefined;
    const formik = useFormik<FormValues>({
        initialValues: {
            title: video?.title ?? '',
            categoryName: video?.categoryName ?? '',
            url: video?.url ?? '',
        },
        onSubmit: async (values, helpers) => {
            try {
                if (isEdit) {
                    await apiClient?.videosPUT(video.id, new UpdateVideoBindingModel({
                        title: values.title,
                        categoryName: values.categoryName !== '' ? values.categoryName : null,
                    }));
                } else {
                    await apiClient?.videosAll(new CreateVideosBindingModel({
                        videos: [
                            new CreateVideoBindingModel({
                                url: values.url,
                                title: values.title,
                                categoryName: values.categoryName !== '' ? values.categoryName : null,
                            })
                        ]
                    }));
                }
                onSave();
            } catch (e) {
                console.error(e);
            } finally {
                helpers.setSubmitting(false);
            }
        }
    });

    const handleUrlBlur = async () => {
        if (!formik.values.url) return;

        setResolvingVideoTitle(true);
        try {
            const video = await apiClient?.videoResolver(new ResolveVideoBindingModel({
                url: formik.values.url,
            }));
            formik.setFieldValue('title', video!.title);
        } catch (e) {
            console.error(e)
        } finally {
            setResolvingVideoTitle(false);
        }
    }

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Stack gap={3}>
                <fieldset disabled={formik.isSubmitting || resolvingVideoTitle}>
                    <Stack gap={3}>
                        <Form.Group>
                            <Form.Label>Url</Form.Label>
                            <Form.Control type="text" required={true} name="url" value={formik.values.url}
                                onChange={formik.handleChange} onBlur={handleUrlBlur} disabled={isEdit} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" required={true} name="title" value={formik.values.title}
                                onChange={formik.handleChange} />
                        </Form.Group>

                        <Form.Group>
                            <CategorySelect name={`categoryName`} categories={categories} onChange={formik.handleChange}
                                value={formik.values.categoryName} />
                        </Form.Group>
                    </Stack>
                </fieldset>

                <span>
                    <Button variant="primary" type="submit"
                        disabled={!formik.isValid || formik.isSubmitting || resolvingVideoTitle}>
                        {isEdit ? 'Edit' : 'Add'} video
                    </Button>
                </span>
            </Stack>
        </Form>
    );
}