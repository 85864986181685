import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { VideoResult } from "./videoResult";
import {
    TagWithVideoCountViewModel,
    ToggleVideoTagBindingModel,
    VideoLibraryApiClient, VideoViewModel,
    VideoViewModelPaginatedResultsViewModel
} from "../apiClient";
import { AppPagination } from "./appPagination";

type Props = {
    paginatedResults: VideoViewModelPaginatedResultsViewModel;
    apiClient: VideoLibraryApiClient;
    tags: TagWithVideoCountViewModel[],
    onVideoDeleted: () => void;
    onVideoToggleTag: (video: VideoViewModel, bindingModel: ToggleVideoTagBindingModel) => void;
    onPageChange: (page: number) => void;
    onTagClick: (tag: string) => void;
}

export const VideoResults = ({
    paginatedResults,
    apiClient,
    tags,
    onVideoDeleted,
    onVideoToggleTag,
    onTagClick,
    onPageChange,
}: Props) => {
    return (
        <>
            {paginatedResults.totalCount === 0 && (
                <Alert variant="info">
                    No videos found.
                </Alert>
            )}

            {paginatedResults.totalCount !== 0 && (
                <>
                    <Row className="g-3">
                        {paginatedResults?.results?.map((video) => (
                            <Col key={video.id} lg={3}>
                                <VideoResult video={video} apiClient={apiClient!} onVideoDeleted={onVideoDeleted}
                                    onVideoToggleTag={(bindingModel) => onVideoToggleTag(video, bindingModel)}
                                    onTagClick={onTagClick}
                                    tags={tags} />
                            </Col>
                        ))}
                    </Row>
                    <div className="p-3 d-flex justify-content-center">
                        <AppPagination page={paginatedResults.page!} pageSize={paginatedResults.pageSize!}
                            totalPages={paginatedResults.totalPages!}
                            totalCount={paginatedResults.totalCount!}
                            onPageChange={(page) => onPageChange(page)} />
                    </div>
                </>
            )}
        </>
    )
}