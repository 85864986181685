import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";

import { AppLayout } from "../components/appLayout";
import useApiClient from "../hooks/useApiClient";
import { CategoryWithCountsViewModel } from "../apiClient";
import { BookmarkAddEditForm } from "../components/forms/bookmarkAddEditForm";

export const BookmarkAdd = () => {
    const apiClient = useApiClient();
    const navigate = useNavigate();
    const [categories, setCategories] = useState<CategoryWithCountsViewModel[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!apiClient) return;

        const loadCategories = async () => {
            const categories = await apiClient.categoriesAll();
            setCategories(categories);
            setLoading(false);
        };

        loadCategories();
    }, [apiClient]);

    return (
        <AppLayout loading={loading}>
            <Container fluid>
                <Breadcrumb>
                    <LinkContainer to={`/bookmarks`}>
                        <Breadcrumb.Item>Bookmarks</Breadcrumb.Item>
                    </LinkContainer>
                    <Breadcrumb.Item active>Add bookmark</Breadcrumb.Item>
                </Breadcrumb>

                <h1>Add bookmark</h1>
                <BookmarkAddEditForm apiClient={apiClient!} categories={categories} onSave={() => navigate(`/bookmarks`)}/>
            </Container>
        </AppLayout>
    );
}