import { useCallback, useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { useSearchParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/esm/Alert";
import Stack from "react-bootstrap/esm/Stack";

import { AppLayout } from "../components/appLayout";
import useApiClient from "../hooks/useApiClient";
import { BookmarkViewModel, BookmarkViewModelPaginatedResultsViewModel } from "../apiClient";
import { uncategorizedText } from "../constants";
import { confirmAction } from "../helpers/alertHelper";
import { AppPagination } from "../components/appPagination";
import { AppSpinner } from "../components/appSpinner";

type SearchOptions = {
    page: number;
    query?: string;
}

const getSearchOptionsFromUrlParams = (urlSearchParams: URLSearchParams): SearchOptions => {
    const page = urlSearchParams.get('page') ? parseInt(urlSearchParams.get('page')!) : 1;
    const query = urlSearchParams.get('query') ?? undefined;
    return {
        page,
        query
    };
};

// TODO: Defer search until user stops typing
export const Bookmarks = () => {
    const PAGE_SIZE = 100;

    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const [paginatedResults, setPaginatedResults] = useState<BookmarkViewModelPaginatedResultsViewModel>();
    const [searchOptions, setSearchOptions] = useState<SearchOptions>(getSearchOptionsFromUrlParams(urlSearchParams));
    const [loading, setLoading] = useState<boolean>(true);
    const [performingAction, setPerformingAction] = useState<boolean>(false);
    const apiClient = useApiClient();

    const loadData = useCallback(async () => {
        if (!apiClient) return;
        setLoading(true);
        const bookmarks = await apiClient.bookmarksGET(searchOptions.page, PAGE_SIZE, searchOptions.query);
        setPaginatedResults(bookmarks);
        setLoading(false);
    }, [apiClient, searchOptions]);

    useEffect(() => {
        loadData();
    }, [searchOptions, loadData]);

    useEffect(() => {
        setUrlSearchParams(prev => {
            prev.set('page', searchOptions.page?.toString() ?? '1');
            prev.set('query', searchOptions.query ?? '');
            return prev;
        }, { replace: true });
    }, [searchOptions, setUrlSearchParams]);

    const deleteBookmark = async (bookmark: BookmarkViewModel) => {
        if (!await confirmAction()) return;
        setPerformingAction(true);

        try {
            await apiClient?.bookmarksDELETE(bookmark.id!);
            await loadData();
        } finally {
            setPerformingAction(false);
        }
    }

    const importBookmark = async (bookmark: BookmarkViewModel) => {
        setPerformingAction(true);

        try {
            await apiClient?.importAsVideo(bookmark.id!);
            setPerformingAction(false);
            await loadData();
        } finally {
            setPerformingAction(false);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        setSearchOptions({
            ...searchOptions,
            page: pageNumber
        });
    }

    const handleSearchChange = (query: string) => {
        setSearchOptions({
            ...searchOptions,
            query,
            page: 1
        });
    }

    return (
        <AppLayout loading={!paginatedResults && loading}>
            <Container fluid>
                {paginatedResults &&
                    <Stack gap={3}>
                        <Row>
                            <Col>
                                <h1>Bookmarks ({paginatedResults?.totalCount})</h1>
                            </Col>
                            <Col xs="auto">
                                <LinkContainer to={"/bookmarks/add"}>
                                    <Button>Add bookmark</Button>
                                </LinkContainer>
                            </Col>
                        </Row>

                        <input type="text" className="form-control" placeholder="Search" value={searchOptions.query ?? ''} onChange={e => handleSearchChange(e.target.value)} />

                        {loading && (
                            <AppSpinner />
                        )}

                        {!loading && (
                            <>
                                {paginatedResults.totalCount === 0 && (
                                    <Alert variant="info">
                                        No bookmarks found.
                                    </Alert>
                                )}

                                {paginatedResults.totalCount! > 0 && (
                                    <Table striped bordered hover responsive className="bookmarks-table">
                                        <thead>
                                            <tr>
                                                <th className="bookmarks-table__col bookmarks-table__col--name">Name</th>
                                                <th className="bookmarks-table__col bookmarks-table__col--category">Category</th>
                                                <th className="bookmarks-table__col bookmarks-table__col--url">Url</th>
                                                <th className="bookmarks-table__col bookmarks-table__col--actions">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedResults?.results?.map((bookmark) => (
                                                <tr key={bookmark.id}>
                                                    <td className="bookmarks-table__col bookmarks-table__col--name">{bookmark.name}</td>
                                                    <td className="bookmarks-table__col bookmarks-table__col--category">{bookmark.categoryName ?? uncategorizedText}</td>
                                                    <td className="bookmarks-table__col bookmarks-table__col--url"><a href={bookmark.url!} rel="noreferrer noreferrer" target="_blank">{bookmark.url}</a>
                                                    </td>
                                                    <td className="bookmarks-table__col bookmarks-table__col--actions">
                                                        <ButtonGroup>
                                                            <LinkContainer to={`/bookmarks/${bookmark.id}/edit`}>
                                                                <Button variant="warning" disabled={performingAction}>Edit</Button>
                                                            </LinkContainer>
                                                            <Button variant="success" onClick={() => importBookmark(bookmark)}
                                                                disabled={performingAction}>Import</Button>
                                                            <Button variant="danger" onClick={() => deleteBookmark(bookmark)}
                                                                disabled={performingAction}>Delete</Button>
                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}

                                <div className="d-flex justify-content-center">
                                    <AppPagination page={paginatedResults.page!} pageSize={paginatedResults.pageSize!}
                                        totalPages={paginatedResults.totalPages!} totalCount={paginatedResults.totalCount!}
                                        onPageChange={(pageNumber) => handlePageChange(pageNumber)} />
                                </div>
                            </>)}
                    </Stack>
                }
            </Container>
        </AppLayout>
    )
}