import { AppLayout } from "../components/appLayout";
import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/esm/Stack";
import Button from "react-bootstrap/Button";
import useApiClient from "../hooks/useApiClient";
import { useState } from "react";

export const Backup = () => {
    const apiClient = useApiClient();
    const [loading, setLoading] = useState<boolean>(false);

    const onBackup = async () => {
        setLoading(true);

        try {
            const backup = await apiClient!.backupsGET();
            const file = new Blob([JSON.stringify(backup.toJSON())], { type: 'application/json' });
            const url = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.download = `videolibrary-backup-${new Date().toISOString()}.json`;
            link.click();
            URL.revokeObjectURL(url);
        } finally {
            setLoading(false);
        }
    };

    const onRestore = () => {
        setLoading(true);

        try {
            // Prompt user to upload file
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = '.json';
            fileInput.click();
            fileInput.onchange = () => {
                const file = fileInput.files![0];
                console.log(file);
            };
        } finally {
            setLoading(false);
        }
    };

    return (
        <AppLayout>
            <Container fluid>
                <h1>Backup</h1>
                <Stack direction="horizontal" gap={2}>
                    <Button variant="primary" onClick={onBackup} disabled={loading}>Backup</Button>
                    <Button variant="secondary" onClick={onRestore} disabled={loading}>Restore</Button>
                </Stack>
            </Container>
        </AppLayout>
    );
}