import swal from "sweetalert";

export const confirmAction = async (title?: string): Promise<boolean> => {
    const result = await swal({
        title: title ?? "Are you sure?",
        icon: "warning",
        buttons: ["Cancel", "OK"],
        dangerMode: true,
    });

    return !!result;
}
