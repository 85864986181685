import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";

import { AppLayout } from "../components/appLayout";
import useApiClient from "../hooks/useApiClient";
import { CreateCategoryBindingModel } from "../apiClient";

interface FormValues {
    name: string;
}

export const CategoryAdd = () => {
    const apiClient = useApiClient();
    const navigate = useNavigate();

    const formik = useFormik<FormValues>({
        initialValues: {
            name: ''
        },
        onSubmit: async (values, helpers) => {
            try {
                await apiClient?.categoriesPOST(new CreateCategoryBindingModel({
                    name: values.name
                }));
                navigate(`/categories`);
            } catch (error) {
                // TODO: handle error
                console.error(error);
                return;
            } finally {
                helpers.setSubmitting(false);
            }
        },
    });

    return (
        <AppLayout>
            <Container fluid>
                <Breadcrumb>
                    <LinkContainer to={`/categories`}>
                        <Breadcrumb.Item>Categories</Breadcrumb.Item>
                    </LinkContainer>
                    <Breadcrumb.Item active>Add category</Breadcrumb.Item>
                </Breadcrumb>

                <h1>Add category</h1>

                <Form onSubmit={formik.handleSubmit}>
                    <fieldset disabled={formik.isSubmitting}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" required={true} name="name" value={formik.values.name}
                                          onChange={formik.handleChange}/>
                        </Form.Group>
                    </fieldset>

                    <Button variant="primary" type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                        Add category
                    </Button>
                </Form>
            </Container>
        </AppLayout>
    );
}