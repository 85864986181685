import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import Stack from "react-bootstrap/esm/Stack";

import { AppLayout } from "../components/appLayout";
import useApiClient from "../hooks/useApiClient";
import {
    CategoryWithCountsViewModel,
    SearchVideosOptionsBindingModel,
    TagWithVideoCountViewModel,
    ToggleVideoTagBindingModel,
    VideoViewModel,
    VideoViewModelPaginatedResultsViewModel
} from "../apiClient";
import { VideoResults } from "../components/videoResults";

const getSearchOptionsBindingModelFromUrlSearchParams = (urlSearchParams: URLSearchParams): SearchVideosOptionsBindingModel => {
    const categoryName = urlSearchParams.get('categoryName');
    const page = urlSearchParams.get('page') ? parseInt(urlSearchParams.get('page')!) : 1;
    const query = urlSearchParams.get('query') ?? null;
    const tags = urlSearchParams.getAll('tag');
    return new SearchVideosOptionsBindingModel({
        categoryName: categoryName ?? null,
        page,
        pageSize: 40,
        query,
        tags
    });
};

export const Home = () => {
    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const setUrlSearchParamsRef = useRef(setUrlSearchParams);
    setUrlSearchParamsRef.current = setUrlSearchParams;

    const apiClient = useApiClient();
    const [categories, setCategories] = useState<CategoryWithCountsViewModel[]>([]);
    const [videoPaginatedResults, setVideoPaginatedResults] = useState<VideoViewModelPaginatedResultsViewModel | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchVideosOptions, setSearchVideosOptions] = useState<SearchVideosOptionsBindingModel>(getSearchOptionsBindingModelFromUrlSearchParams(urlSearchParams));
    const [tags, setTags] = useState<TagWithVideoCountViewModel[]>([]);

    const loadData = useCallback(async () => {
        const updateUrlSearchParams = (searchVideosOptions: SearchVideosOptionsBindingModel) => {
            const newUrlSearchParams = new URLSearchParams();
            newUrlSearchParams.set('categoryName', searchVideosOptions.categoryName ?? '');
            newUrlSearchParams.set('page', searchVideosOptions.page?.toString() ?? '');
            newUrlSearchParams.set('query', searchVideosOptions.query ?? '');
            newUrlSearchParams.delete('tag');
            searchVideosOptions.tags?.forEach(tag => newUrlSearchParams.append('tag', tag));
            setUrlSearchParamsRef.current(newUrlSearchParams);
        }

        if (!apiClient) return;
        setLoading(true);
        const allCategoriesPromise = apiClient.categoriesAll().then(c => setCategories(c));
        const tagsPromise = apiClient.tagsAll().then(t => setTags(t));
        const videoSearchResultsPromise = apiClient.search(searchVideosOptions).then(r => setVideoPaginatedResults(r));
        await Promise.all([allCategoriesPromise, videoSearchResultsPromise, tagsPromise]);
        setLoading(false);
        updateUrlSearchParams(searchVideosOptions);
    }, [apiClient, searchVideosOptions, setUrlSearchParamsRef]);

    const handleSelectCategory = (category: CategoryWithCountsViewModel) => {
        setSearchVideosOptions(new SearchVideosOptionsBindingModel({
            ...searchVideosOptions,
            categoryName: category.name !== searchVideosOptions.categoryName ? category.name : null,
            page: 1
        }));
    }

    const handlePageChange = (pageNumber: number) => {
        setSearchVideosOptions(new SearchVideosOptionsBindingModel({
            ...searchVideosOptions,
            page: pageNumber
        }));
    }

    const handleTagClick = (tag: string) => {
        if (!searchVideosOptions.tags?.includes(tag)) {
            setSearchVideosOptions(new SearchVideosOptionsBindingModel({
                ...searchVideosOptions,
                tags: [...searchVideosOptions.tags!, tag]
            }));
        }
    }

    const handleRemoveTagFromSearchOptions = (tag: string) => {
        setSearchVideosOptions(new SearchVideosOptionsBindingModel({
            ...searchVideosOptions,
            tags: searchVideosOptions.tags?.filter(t => t !== tag)
        }));
    }

    const handleRemoveQuery = () => {
        setSearchVideosOptions(new SearchVideosOptionsBindingModel({
            ...searchVideosOptions,
            query: null
        }));
    }

    const handleVideoToggleTag = async (video: VideoViewModel, bindingModel: ToggleVideoTagBindingModel) => {
        try {
            const result = await apiClient!.tags(video.id!, bindingModel);
            const updatedResults = new VideoViewModelPaginatedResultsViewModel({ ...videoPaginatedResults });
            const videoIndex = updatedResults.results!.findIndex(v => v.id === video.id);

            if (searchVideosOptions.tags?.length !== 0 && !result.tags?.some(t => searchVideosOptions.tags?.includes(t))) {
                // Remove video from results if it doesn't have any of the tags in the search options
                updatedResults.results!.splice(videoIndex, 1);
            } else if (videoIndex !== -1) {
                // Update video in results
                updatedResults.results![videoIndex] = result;
            }

            setVideoPaginatedResults(updatedResults);
            setTags(await apiClient?.tagsAll()!);
        } catch (err) {
            console.error(err);
            loadData();
        }
    }

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        const query = urlSearchParams.get('query');
        if (query && query.length > 0 && query !== searchVideosOptions.query) {
            setSearchVideosOptions(new SearchVideosOptionsBindingModel({
                ...searchVideosOptions,
                query
            }));
        } else {
            urlSearchParams.delete('query');
        }
    }, [urlSearchParams, searchVideosOptions]);

    return (
        <AppLayout loading={loading}>
            <Container fluid>
                <Row className="g-3">
                    <Col lg={2}>
                        <h2>Categories <Link to={`/categories/add`}>+</Link></h2>
                        <ListGroup>
                            {categories.map((category) => (
                                <ListGroup.Item key={category.name}
                                    className="d-flex justify-content-between align-items-center"
                                    active={searchVideosOptions.categoryName === category.name}
                                    onClick={() => handleSelectCategory(category)}
                                    role="button">
                                    {category.name}
                                    <Badge>{category.videoCount}</Badge>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                    <Col>
                        <Stack gap={3}>
                            <h2>{!searchVideosOptions.categoryName ? 'All videos' : searchVideosOptions.categoryName} ({videoPaginatedResults?.totalCount})</h2>
                            {((searchVideosOptions.tags?.length !== 0) || searchVideosOptions.query) &&
                                <Stack direction="horizontal" gap={2} className="flex-wrap">
                                    {searchVideosOptions.query &&
                                        <Badge pill className="p-2">
                                            <Stack direction="horizontal" gap={2}>
                                                Search: {searchVideosOptions.query} <span className="text-danger" role="button" onClick={handleRemoveQuery}>
                                                    <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                                </span>
                                            </Stack>
                                        </Badge>
                                    }
                                    {searchVideosOptions.tags?.map((tag) => (
                                        <Badge key={tag} pill className="p-2">
                                            <Stack direction="horizontal" gap={2}>
                                                {tag} <span className="text-danger" role="button" onClick={() => handleRemoveTagFromSearchOptions(tag)}>
                                                    <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                                </span>
                                            </Stack>
                                        </Badge>
                                    ))}
                                </Stack>
                            }
                            <VideoResults
                                paginatedResults={videoPaginatedResults!}
                                apiClient={apiClient!}
                                onVideoDeleted={loadData}
                                onPageChange={(page) => handlePageChange(page)}
                                onVideoToggleTag={(video: VideoViewModel, bindingModel: ToggleVideoTagBindingModel) => handleVideoToggleTag(video, bindingModel)}
                                onTagClick={(tag) => handleTagClick(tag)}
                                tags={tags} />
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </AppLayout>
    );
}
